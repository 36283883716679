import React from "react";
import Seo from "../components/seo/seo";
import { useIntl } from "gatsby-plugin-intl";
import { Layout } from "../components/layout/layout";
import style from "../assets/css/partner.module.css";
import { PartnerBanner } from "../components/partnerBanner/partnerBanner";
import { SsyIntersectionObserver } from "../components/ssyIntersectionObserver/ssyIntersectionObserver";
import { SsyLink } from "../components/ssyLink/ssyLink";
import { RaisingBox } from "../components/raisingBox/raisingBox";
import AnimatedHero2 from "../components/animatedHero/animatedHero";
import {GifWithFooter} from "../components/gifWithFooter/gifWithFooter"
import {HeaderType} from "../components/header/header";
const PartnerPage = (props) => {
  const intl = useIntl();
  const gifList = [
    {
      name: "free-wedding-app-samsaidyes",
      title: "PARTNER_REASON1_TITLE",
    },
    {
      name: "slideshow-samsaidyes",
      title: "PARTNER_REASON2_TITLE",
    },
    {
      name: "unlimited-photos-samsaidyes",
      title: "PARTNER_REASON3_TITLE",
    },
  ];

  return (
    <Layout headerType={HeaderType.partner}>
      <Seo
        title={intl.formatMessage({ id: "HOME_META_TITLE" })}
        url={props.location.pathname}
        lang={intl.formatMessage({ id: "MAIN_LANGUAGE" })}
        description={intl.formatMessage({ id: "HOME_META_DESCRIPTION" })}
      />
      <main className={style.pageStyles}>
        <SsyIntersectionObserver>
          <RaisingBox>
            <AnimatedHero2 />
            <section className={`${style.firstSection} ${style.section}`}>
              <h1>{intl.formatMessage({ id: "PARTNER_TITLE" })}</h1>
              <SsyLink
                to={`${
                  process.env.GATSBY_PARTNER_DASHBOARD_URL
                }/${intl.formatMessage({
                  id: "MAIN_LANGUAGE",
                })}/register`}
                id="PARTNER_INTRO_BUTTON"
                type="mainCta"
                internal={false}
                label={intl.formatMessage({
                  id: "PARTNER_INTRO_BUTTON",
                })}
              />
            </section>
          </RaisingBox>
        </SsyIntersectionObserver>
        <SsyIntersectionObserver type="lightGrey">
        <RaisingBox>
          <section className={`${style.secondSection} ${style.section}`}>
            <p className={style.publicatedTitle}>
              {intl.formatMessage({ id: "LANDING_FEATURED" })}
            </p>
            <PartnerBanner />
          </section>
          </RaisingBox>
        </SsyIntersectionObserver>
        <SsyIntersectionObserver>
          <RaisingBox>
            <section className={style.eightSection}>
              <h2>{intl.formatMessage({ id: "PARTNER_MODULE1_TITLE" })}</h2>
              <p>{intl.formatMessage({ id: "PARTNER_MODULE1_MESSAGE" })}</p>
              <ol className={style.gifList}>
                {gifList.map((item) => {
                  return (
                    <li key={item.name}>
                      <GifWithFooter intl={intl} name={item.name} title={item.title} />
                    </li>
                  );
                })}
              </ol>
              <SsyLink
                type="primary"
                id="PARTNER_REGISTER_SECTION_BUTTON"
                to={`${
                  process.env.GATSBY_PARTNER_DASHBOARD_URL
                }/${intl.formatMessage({
                  id: "MAIN_LANGUAGE",
                })}/register`}
                internal={false}
                label={intl.formatMessage({ id: "NAVIGATION_REGISTER" })}
              />
            </section>
          </RaisingBox>
        </SsyIntersectionObserver>
      </main>
    </Layout>
  );
};

export default PartnerPage;
