import React from "react";
import style from "./gifWithFooter.module.css";
import { LazyVideo } from "../lazyVideo/lazyVideo";

export const GifWithFooter = ({ name, title, intl }) => {
  return (
    <article className={style.gif}>
      <LazyVideo name={name} type="normal" />
      <h2>{intl.formatMessage({ id: title })}</h2>
    </article>
  );
};
