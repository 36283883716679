import React, { useEffect, useRef, useState } from 'react'

import style from './lazyVideo.module.css'
import Video1 from '../../assets/images/unlimited-photos-samsaidyes.mp4'
import Video2 from '../../assets/images/free-wedding-app-samsaidyes.mp4'
import Video3 from '../../assets/images/slideshow-samsaidyes.mp4'
const POSTER_B64 =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/R8AAvsB/ORwHosAAAAASUVORK5CYII='
export const LazyVideo = ({ name, type }) => {

  const getVideo = () => {
    switch (name) {
      case "unlimited-photos-samsaidyes":
          return Video1;
        case "free-wedding-app-samsaidyes":
          return Video2;
        case "slideshow-samsaidyes":
          return Video3;
      default:
        return Video3;
    }
  }
  
  return (
    <video
      className={`${style.video} ${style[type]}`}
    >
      <source src={getVideo()} type="video/mp4" />
    </video>
  )
}
