import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import style from "./animatedHero.module.css";

const AnimatedHero2 = ({ isMartha = false }) => {
  const [width, setwidth] = useState(0);
  useEffect(() => {
    manageWidth();
    window.addEventListener("resize", manageWidth);
    return () => {
      window.removeEventListener("resize", manageWidth);
    };
  }, []);

  const manageWidth = () => {
    setwidth(window.innerWidth);
  };

  const config =isMartha ? [] :[
    {
      image: "emoji01", // emoji llanto
      top: "0",
      left: "20",
      rotation: "left",
      duration: 4,
    },
    {
      image: "emoji02", //emoji corazon
      left: "8",
      top: "70",
      rotation: "left",
      duration: 4,
    },
    {
      image: "emoji03",
      left: "85",
      top: "72",
      rotation: "left",
      duration: 4,
    },
    {
      image: "shape01", // cubo
      left: "20",
      top: "80",
      rotation: "left",
      duration: 6,
    },
    {
      image: "emoji03", // shape rombo // TODO REVIEW
      left: "8",
      top: "26",
      rotation: "rigth",
      duration: 3,
    },
    {
      image: "shape03", //esfera
      left: "10",
      top: "40",
      rotation: "rigth",
      duration: 4,
    },
    {
      image: "shape04", // palmas
      left: "75",
      top: "0",
      rotation: "rigth",
      duration: 4,
    },
    {
      image: "shape05",
      left: "40",
      top: "-10",
      rotation: "rigth",
      duration: 4,
    },
    {
      image: "emoji8",
      left: "70",
      top: "35",
      rotation: "left",
      duration: 6,
    },
    {
      image: "emoji01", // emoji llanto
      top: "-5",
      left: "65",
      rotation: "left",
      duration: 4,
    },
  ];

  const data = useStaticQuery(graphql`
    query {
      mainBanner2: file(relativePath: { eq: "Hands-white-BG-Desktop.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
            placeholder: NONE
            layout: CONSTRAINED
          )
        }
      }
      mainBannerMartha: file(
        relativePath: { eq: "iPhone-Marthas-variante2.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
            placeholder: NONE
            layout: CONSTRAINED
          )
        }
      }
      emoji01: file(relativePath: { eq: "icon-8.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 35
            formats: [WEBP]
            layout: FIXED
            placeholder: NONE
          )
        }
      }
      emoji02: file(relativePath: { eq: "icon-9.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 35
            formats: [WEBP]
            layout: FIXED
            placeholder: NONE
          )
        }
      }
      emoji03: file(relativePath: { eq: "icon-10.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 35
            formats: [WEBP]
            layout: FIXED
            placeholder: NONE
          )
        }
      }
      emoji8: file(relativePath: { eq: "geometric-blue-1.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 50
            formats: [PNG]
            layout: FIXED
            placeholder: NONE
          )
        }
      }
      shape01: file(relativePath: { eq: "icon-1.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 35
            formats: [WEBP]
            layout: FIXED
            placeholder: NONE
          )
        }
      }
      shape02: file(relativePath: { eq: "icon-2.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 35
            formats: [WEBP]
            layout: FIXED
            placeholder: NONE
          )
        }
      }
      shape03: file(relativePath: { eq: "geometric-purple-5.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 70
            formats: [PNG]
            layout: FIXED
            placeholder: NONE
          )
        }
      }
      shape04: file(relativePath: { eq: "icon-5.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 50
            formats: [PNG]
            layout: FIXED
            placeholder: NONE
          )
        }
      }
      shape05: file(relativePath: { eq: "icon-6.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 35
            formats: [WEBP]
            layout: FIXED
            placeholder: NONE
          )
        }
      }
      shape06: file(relativePath: { eq: "icon-7.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 35
            formats: [WEBP]
            layout: FIXED
            placeholder: NONE
          )
        }
      }
    }
  `);

  return (
    <section className={isMartha ? style.heroAnimatedContainerMartha : style.heroAnimatedContainer}>
      <div className={style.heroAnimated}>
        {config.map((item, i) => {
          return (
            <span
              key={i}
              className={`${style.heroItem} ${style[item.rotation]}`}
              style={{
                animationDuration: `${item.duration}s`,
                left: `${item.left}%`,
                top: `${item.top}%`,
              }}
            >
              <GatsbyImage
                alt="main samsaidyes banner"
                image={getImage(
                  data[item.image].childImageSharp.gatsbyImageData
                )}
              />
            </span>
          );
        })}
      </div>
      {width !== 0 && (
        <div className={style.mainImageContainer}>
          <GatsbyImage
            alt="main samsaidyes banner"
            image={
              isMartha
                ? getImage(data.mainBannerMartha.childImageSharp.gatsbyImageData)
                : getImage(data.mainBanner2.childImageSharp.gatsbyImageData)
            }
            className={`${style.mainImage} ${style.desktop}`}
          />
        </div>
      )}
    </section>
  );
};

export default AnimatedHero2;
